<!--  -->
<template>
  <div class="share-page">
    <img src="../../assets/img/winback.png" class="title">
    <img src="../../assets/img/detail.png" class="detail">
    <div
      v-if="isInWeChatBrowser"
      class="wexin-launch-btn"
    >
      <div class="btn" @click="newOpenHandle">下载赢回来APP</div>
      <wx-open-launch-app
        id="launch-btn"
        appid="wxbd4e3ed4f6fdc955"
        :extinfo="openUrl"
        style="width: 100%; height: 60px; display: block;"
        @error="handleErrorFn" 
        @launch="handleLaunchFn"
      >
      <!-- vue2不能直接用script标签，这里需要用component处理一下 -->
        <component :is="scriptStr" type="text/wxtag-template">
          <button class="white btn" style="display: block;font-size:16px;margin:12px auto 0;border:1px solid #d2d2d2;color:#333;background-color: #fff;font-weight: 500;width: 90%;height: 46px;border-radius: 23px;-webkit-appearance:none;outline:none;-webkit-tap-highlight-color:rgba(0,0,0,0);">已下载 立即打开</button>
        </component>
      </wx-open-launch-app>
    </div>
    <div v-else>
      <div class="btn" @click="downHandle" id="downbtn">下载赢回来APP</div>
      <div class="white btn" @click="openHandle" id="openApp">已下载 立即打开</div>
    </div>
    
    <div class="footer">
      上海百昌网络拍卖科技有限公司
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {getWxConfig} from '../../api/open'
// 开放标签属于自定义标签，Vue会给予未知标签的警告,忽略vue对开放标签的检查
Vue.config.ignoredElements = [
    'wx-open-launch-app'
];
export default {
  data () {
    return {
      scriptStr:'script',
      user:'',
      id:'',
      page:'casedetail',
      iosHref:'itms-apps://itunes.apple.com/cn/app/id1628999330',
      androidHref:'https://a.app.qq.com/o/simple.jsp?pkgname=com.yhl.winyinghuilai',
      isInWeChatBrowser:false,
      extinfo:'',
      appId:'wxbd4e3ed4f6fdc955', // 移动应用ID android:wxbd4e3ed4f6fdc955  ios:wxbd4e3ed4f6fdc955
      openUrl:'',
      newAlert:null
    };
  },
  components: {},
  computed: {},
  methods: {
    handleErrorFn(e) {
      // 跳转失败-跳到app下载页面
      console.log(e.detail);
      // alert(e.detail.errMsg);//注意这里不要将e序列化输出，不然手机端alert会是空值，这样就不能得到报错信息，序列化：即alert('e:',JSON.stringify(e))之类
      this.newOpenHandle(); //此处是自定义逻辑，一般是用户没下载app时触发，既跳转到让用户前往下载的app的界面
    },
    handleLaunchFn(){
      // 跳转成功
      console.log('跳转成功')
    },
    newOpenHandle(){
      if(this.user === 'ios'){
        window.location.href = "https://itunes.apple.com/cn/app/id1628999330";
      }else if(this.user === 'android'){
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.yhl.winyinghuilai";
      }
    },
    wxConfig(){
      //这个为后端获取wx配置的必要信息
      //需要先调用接口获取信息
      let url = location.href.split('#')[0];
      getWxConfig({url:url}).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          let configData = JSON.parse(data.content) || {};
          wx.config({
            debug:false,// 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
            appId:configData.appid,// 必填，公众号的唯一标识
            timestamp:configData.timestamp,// 必填，生成签名的时间戳
            nonceStr:configData.nonceStr,// 必填，生成签名的随机串
            signature:configData.signature,// 必填，签名
            jsApiList: ['onMenuShareAppMessage'], // 必填，需要使用的 JS 接口列表
            openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          });
          wx.ready(function () {
            console.log('ready')
            // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中
          });
          wx.error(function (res) {
            console.log(res);
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
          });
        }else{
          console.log(data.desc);
        }
      })      
    },
    downHandle(){
      if(this.user === 'ios'){
        window.location.href = this.iosHref;
      }else if(this.user === 'android'){
        window.location.href = this.androidHref;
      }

    },
    openHandle(){
      if(this.user === 'android'){
          let ifr = document.createElement('iframe');
          ifr.src = 'yinghuilai://gotopage?page='+ this.page +'&caseid='+this.id;
          ifr.style.display = 'none';
          document.body.appendChild(ifr);
          setTimeout(()=>{
              let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden;
              if(typeof hidden =="undefined" || hidden ==false){
                  //应用宝下载地址 
                  window.location.href = this.androidHref;
              }
          }, 2000);
        }else if(this.user === 'ios'){
          //ios的scheme协议
          window.location.href = 'yinghuilai://gotopage?page='+ this.page +'&caseid='+this.id;
          setTimeout(()=>{
              let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden;
              if(typeof hidden =="undefined" || hidden ==false){
                  //App store下载地址
                  window.location.href = this.iosHref;
              }
          }, 2000);
        }else{
          alert('您当前的设备可能无法自动唤起');
        }
    },
  },
  mounted(){
    document.body.style.minWidth = 'auto';
    this.newAlert = function (name) {
      const iframe = document.createElement('IFRAME');
      iframe.style.display = 'none';
      iframe.setAttribute('src', 'data:text/plain,');
      document.documentElement.appendChild(iframe);
      window.frames[0].window.alert(name);
      iframe.parentNode.removeChild(iframe);
    }
  },
  created(){
    if(this.$route.query){
      this.id = this.$route.query.caseid;
      this.page = this.$route.query.page;
    }
    this.openUrl = 'yinghuilai://gotopage?page='+ this.page +'&caseid='+this.id;
    var ua = navigator.userAgent;
    if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
        this.user = 'ios';
    } else if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {
        this.user = 'android';
    }
    this.isInWeChatBrowser = /micromessenger/i.test(ua); // 判断是否运行在微信浏览器中
    console.log(this.user);
    this.wxConfig();
    //isInWeChatBrowser为判断当前浏览器是否为微信浏览器，如果是，才渲染微信开放标签
  }
}

</script>
<style lang='scss' scoped>
.share-page{
  height: 100%;
  text-align: center;
  background: url('../../assets/img/share_bg.png') no-repeat;
  background-origin: left top;
  background-size: 100%;
  .title{
    padding-top: 38px;
    width: 69.333%;
    margin:0 auto;
    display: block;
  }
  .detail{
    margin-top: 40px;
    width: 48%;
  }
  .footer{
    text-align: center;
    font-size: 12px;
    color:#9C9C9C;
    position:absolute;
    bottom:12px;
    left:0;
    right: 0;
  }
}
.btn{
  display: block;
  width: 90%;
  height: 46px;
  border-radius: 23px;
  color:#fff;
  background-color: #C2AA81;
  text-align: center;
  line-height: 46px;
  margin:0 auto;
  font-size: 16px;
  font-weight: 500;
}
.white{
  display: block;
  margin-top: 12px;
  border:1px solid #d2d2d2;
  color:#333;
  background-color: #fff;
}
.wexin-launch-btn{
  height: 50px;
  width: 100%;
}

</style>